import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactNode,
} from "react";
import { SHA256 } from "crypto-js";
import { useData } from "./useData";
import { EmbedData, Provider } from "./EmbedProviderBase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const BASEURL = "https://xw8v-tcfi-85ay.n7.xano.io/api:scripttagme/";

const SharedEmbedProvider: FC<{ guid: string; children: ReactNode }> = ({
  children,
  guid,
}) => {
  // return null;
  const [body, setBody] = useState("");
  const bodyHash = useMemo(() => {
    return SHA256(body).toString();
  }, [body]);
  const [savedBody, setSavedBody] = useState(SHA256("").toString());
  const [loaded, setLoaded] = useState(false);
  const { data, isLoading, error, update, refetch } = useData(
    useCallback(async () => {
      const response = await fetch(`${BASEURL}embed/${guid}`);
      if (response.status !== 200)
        throw new Error("Could not load shared file");
      const data = (await response.json()) as EmbedData;
      return data;
    }, [guid]),
    [guid]
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (error) {
      navigate("/");
      toast.error("Could not load shared file");
    }
  }, [error, navigate]);
  const bodyHashRef = useRef(bodyHash);
  bodyHashRef.current = bodyHash;
  const savedBodyRef = useRef(savedBody);
  savedBodyRef.current = savedBody;
  const [type, setType] = useState("javascript");
  const [description, setDescription] = useState("");
  useEffect(() => {
    if (data) {
      if (!loaded) {
        setBody(data.body);
        setLoaded(true);
        setSavedBody(SHA256(data.body).toString());
        setType(data.type);
        setName(data.name);
        setDescription(data.description);
      } else if (
        SHA256(data.body).toString() !== bodyHashRef.current &&
        bodyHashRef.current === savedBodyRef.current
      ) {
        setBody(data.body);
        setSavedBody(SHA256(data.body).toString());
        setType(data.type);
        setName(data.name);
        setDescription(data.description);
      }
    }
  }, [data, loaded, type]);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const save = useCallback(async () => {
    if (true) return;
    if (!data?.is_shared) return;
    if (saving) return;
    setSaving(true);
    setSavedBody(SHA256(body).toString());
    await fetch(`${BASEURL}embed/${data?.guid}`, {
      method: "POST",
      body: JSON.stringify({ body, type }),
      headers: { "Content-Type": "application/json" },
    });
    setSaving(false);
  }, [saving, data, body, type]);
  const value = useMemo(() => {
    return {
      body,
      setBody,
      type,
      setType,
      isLoading,
      error,
      update,
      refetch,
      save,
      saving,
      data,
      unsaved: bodyHash !== savedBody,
      name,
      setName,
      isShared: data?.is_shared || false,
      setIsShared: () => {
        throw new Error("cannot update share from a share");
      },
      loaded,
      setLoaded,
      description,
      setDescription,
    };
  }, [
    body,
    error,
    isLoading,
    refetch,
    save,
    saving,
    type,
    update,
    data,
    bodyHash,
    savedBody,
    name,
    setName,
    loaded,
    setLoaded,
    description,
    setDescription,
  ]);
  return <Provider value={value}>{children}</Provider>;
};
export default SharedEmbedProvider;
