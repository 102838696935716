import { useCallback } from "react";
import { toast } from "react-toastify";
import { useModalPicker } from "./ModalPicker";
import { SCMProject } from "./Projects";
import { useAuthenticatedFetch } from "./Authenticator";

export const useMoveScript = () => {
  const pick = useModalPicker();
  const fetch = useAuthenticatedFetch();
  return useCallback(
    async (id: number, callback?: (newProjectId: number) => void) => {
      const response = await fetch("/me/project");
      const result = (await response.json()) as {
        items: SCMProject[];
      };
      const projects = result.items;
      const newProjectIdString = await pick({
        title: "Move to Project",
        options: [
          ...projects.map((p) => ({
            title: p.name,
            subtitle: p.description,
            key: p.id.toString(),
          })),
          {
            title: "Unassigned",
            subtitle: "Scripts without projects",
            key: "0",
          },
        ],
      });
      const newProjectId = parseInt(newProjectIdString);
      if (!isNaN(newProjectId)) {
        const response = await fetch(`/me/embed/${id}`, {
          method: "POST",
          body: JSON.stringify({ project_id: newProjectId }),
        });
        if (response.ok) {
          toast.success(
            "Moved to " +
              (projects.find((e) => (e.id = newProjectId))?.name ||
                "Unassigned")
          );
          if (callback) callback(newProjectId);
        } else {
          toast.error("Failed to move to project");
        }
      }
    },
    [pick, fetch]
  );
};
