import { Editor, useMonaco } from "@monaco-editor/react";
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { MonacoBinding } from "y-monaco";
import { editor } from "monaco-editor";
import useYProvider from "y-partykit/react";
import type {} from "y-partykit/react";
import { forwardRef } from "react";

import prettierBabel from "prettier/parser-babel";
import prettierCss from "prettier/parser-postcss";
import { format, check } from "prettier";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
console.log("Including partykit editor");
const EditorWrapper = forwardRef<
  {
    getBinding: () => MonacoBinding | null;
    prettify: () => void;
    prepend: (newText: string) => void;
  },
  {
    docId: number;
    type: string;
    isEditing: boolean;
  }
>(({ docId, type = "javascript", isEditing = true }, ref) => {
  const { pathname } = useLocation();
  console.log("Starting editorwrapper", docId);
  const monaco = useMonaco();
  console.log("monaco", monaco);
  const [binding, setBinding] = useState<MonacoBinding | null>(null);
  const len = monaco?.editor?.getEditors().length;
  const options = useMemo(
    () => ({
      host: new URL("https://partykit-monaco-yjs-party.rhdeck.partykit.dev")
        .hostname,
      room: "scripttag-" + docId,
      options: { connect: true, query: { token: "MYTOKEN" } },
    }),
    [docId]
  );
  const provider = useYProvider(options);
  provider.ws?.send("ping");
  useEffect(() => {
    // return;
    console.log("I am using the newer effect");
    if (!monaco || !monaco.editor) {
      console.log("ABORT");
      return;
    }
    const editor =
      monaco.editor.getEditors()[0] as editor.IStandaloneCodeEditor;
    if (!editor) return;
    const model = editor.getModel();
    if (!model) return;
    //@ts-ignore
    window.mm = monaco;

    const binding = new MonacoBinding(
      provider.doc.getText("monaco"),
      model,
      new Set([editor]),
      provider.awareness
    );
    if (provider.shouldConnect) provider.connect();
    setBinding((old) => {
      if (old) {
        console.log("There is a binding here");
        old.destroy();
      }
      return binding;
    });
    // provider.on("status", (event: any) => {
    //   console.log("status", event);
    //   if (event.status === "disconnected") {
    //     // window.location.reload();
    //   }
    // });
    // provider.on("send", (event: any) => {
    //   console.log("send", event);
    // });
    // // provider.doc.on("update", (event) => {
    // //   console.log("update", event);
    // // });
    // provider.on("sync", (event: any) => {
    //   console.log("sync", event);
    // });
    // provider.on("message", (event: any) => {
    //   console.log("message", event);
    // });
    // return () => {
    //   console.log("destroying because unmount");
    //   binding.destroy();
    //   // provider.destroy();
    // };
  }, [monaco, len, provider]);
  useImperativeHandle(
    ref,
    () => {
      return {
        getBinding: () => {
          return binding;
        },
        prettify: () => {
          try {
            console.log("Prettify me");
            //get current state of the editor
            const body = monaco?.editor?.getModels()[0].getValue();
            if (!body) return;
            const newBody = format(body.replaceAll("\r\n", "\n"), {
              parser: type === "javascript" ? "babel" : "css",
              plugins: [prettierBabel, prettierCss],
            });
            monaco?.editor?.getModels()[0].setValue(newBody);
            if (
              check(newBody, {
                parser: type === "javascript" ? "babel" : "css",
                plugins: [prettierBabel, prettierCss],
              })
            ) {
              toast.success("Prettified code!");
            } else {
              toast.error("Prettified code, but it's still invalid");
            }
          } catch (e) {
            console.error(e);
            toast.error("Failed to prettify: " + (e as Error).message);
          }
        },
        prepend: (newText: string) => {
          monaco?.editor
            ?.getModels()[0]
            .applyEdits([
              { range: new monaco.Range(1, 1, 1, 1), text: newText },
            ]);
          // .setValue(newText + monaco?.editor?.getModels()[0].getValue());
        },
      };
    },
    [binding, monaco, type]
  );
  return (
    <div className="h-full">
      <h2 className="text-lg inline-block font-bold text-white mr-3">
        Partykit editor mode on
      </h2>
      <button
        className="inline-block text-blue-500 hover:text-blue-800"
        onClick={() => {
          window.location.replace(pathname + "?pk=0");
        }}
      >
        Back
      </button>
      <Editor
        // className="my-2"
        language={type || "javascript"}
        theme="vs-dark"
        options={{ wordWrap: "on", readOnly: !isEditing }}
      />
    </div>
  );
});

export default EditorWrapper;
