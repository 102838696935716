import React, { FC, useState, useEffect, useRef } from "react";
import MonacoEditor from "@monaco-editor/react";
import { EmbedData, useBase64Uri, useEmbed } from "./EmbedProviderBase";
import { useHome } from "./HomeBase";
import SidebarPortal from "./SidebarPortal";
import { toast } from "react-toastify";
import {
  ClipboardDocumentIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import { useAuthenticatedFetch, useAuthentication } from "./Authenticator";
import { useNavigate, useLocation } from "react-router-dom";
import WewebEmbed from "./WewebEmbed";
import EmbedTag from "./EmbedTag";
import CTA2 from "./CTA2";
import { XanoClient } from "@xano/js-sdk/lib/client";
let xanoChannel: any | undefined;
const CTAHEIGHT = 300;
const SharedEditor: FC = () => {
  const { pathname } = useLocation();

  const isEmbed = pathname?.startsWith("/embed/");
  const { body, data, isLoading, setBody, type, unsaved, name, refetch } =
    useEmbed();
  const rootRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const { isAuthenticated } = useAuthentication();
  useEffect(() => {
    if (!rootRef.current || !rootRef.current.parentElement?.parentElement)
      return;
    const ho = new ResizeObserver((entries) => {
      setHeight(
        entries[0].contentRect.height -
          (isAuthenticated || isEmbed ? 0 : CTAHEIGHT)
      );
    });
    ho.observe(rootRef.current.parentElement);
    const wo = new ResizeObserver((entries) => {
      // console.log("Width detected", entries[0]);
      setWidth(entries[0].contentRect.width);
    });
    wo.observe(
      rootRef.current.parentElement.parentElement.parentElement ||
        rootRef.current.parentElement.parentElement
    );
    return () => {
      ho.disconnect();
      wo.disconnect();
    };
  }, [data, isAuthenticated, isEmbed]);
  const { setTitle } = useHome();
  useEffect(() => {
    if (name)
      setTitle(
        name +
          (type === "javascript"
            ? ".js"
            : type === "css"
            ? ".css"
            : type === "html"
            ? ".html"
            : "") +
          (isEmbed ? "" : data?.is_shared ? " (shared)" : " (read-only)")
      );
    else setTitle("Untitled Document");
  }, [name, setTitle, unsaved, type, data, isEmbed]);
  const authenticatedFetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const refetchReference = useRef(refetch);
  refetchReference.current = refetch;
  useEffect(() => {
    (async () => {
      if (!data?.guid) return;
      if (xanoChannel) xanoChannel.destroy();
      const xanoClient = new XanoClient({
        instanceBaseUrl: process.env.REACT_APP_XANO_BASE_URL,
        realtimeConnectionHash: process.env.REACT_APP_XANO_RT_HASH,
      });
      const channel = `statechange/${btoa(data?.guid)}`;
      xanoChannel = xanoClient.channel(channel);
      xanoChannel.on(
        (action: { action: string; payload: Record<string, any> }) => {
          if (action.action === "event") {
            if (!action.payload?.data) return;
            refetchReference.current();
          }
        }
      );
    })();
  }, [data?.guid]);

  const getBase64 = useBase64Uri();
  if (!data) return null;
  // if (!width) return null;
  return (
    <div
      className="relative"
      ref={rootRef}
      style={{
        height: height - (isEmbed ? 0 : 25),
        width: width,
        left: 0,
      }}
    >
      <SidebarPortal>
        <div className="text-white flex-col m-2 space-y-4">
          <div className="w-full mx-2">
            <EmbedTag />

            <WewebEmbed />
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                navigator.clipboard.writeText(getBase64());
                toast.success("Copied base64 data URI to clipboard");
              }}
            >
              <ClipboardDocumentIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Copy Base64 data URI
            </button>
            {isAuthenticated && (
              <button
                className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                onClick={async () => {
                  const response = await authenticatedFetch("/me/embed", {
                    method: "POST",
                    body: JSON.stringify({
                      name,
                      type,
                      body,
                    }),
                  });
                  if (response.ok) {
                    const data: EmbedData = await response.json();
                    toast.success("Cloned to your workspace");
                    navigate(`/${data.id}`);
                  } else {
                    toast.error("Failed to clone");
                  }
                }}
              >
                <ClipboardDocumentIcon
                  className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                  aria-hidden="true"
                />
                Clone to my workspace
              </button>
            )}
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                refetch();
              }}
            >
              <CloudArrowDownIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Get updates now
            </button>
          </div>
        </div>
      </SidebarPortal>

      <MonacoEditor
        // height="90vh"

        className={isEmbed ? "" : "my-2"}
        language={type || "javascript"}
        theme="vs-dark"
        value={body}
        options={{ wordWrap: "on", readOnly: true }}
        onChange={(body) => {
          if (!isLoading) {
            console.log("I am updating the body");
            setBody(typeof body !== "undefined" ? body : "");
          }
        }}
      />
      {!isAuthenticated && !isEmbed && (
        <div className=" px-4 overflow-hidden" style={{ height: CTAHEIGHT }}>
          <CTA2 />
        </div>
      )}
    </div>
  );
};

export default SharedEditor;
