import { GlobeAltIcon } from "@heroicons/react/24/outline";
import clipboardCopy from "clipboard-copy";
import { FC } from "react";
import { toast } from "react-toastify";
import { useModalPicker } from "./ModalPicker";
import { useEmbed, useEmbedUrls, usePublish } from "./EmbedProviderBase";
const EmbedRawUrl: FC = () => {
  const pick = useModalPicker();
  const { data } = useEmbed();
  const { id } = data || { id: 0 };
  const { bareDevEmbedUrl } = useEmbedUrls();
  const publish = usePublish();
  return (
    <button
      className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
      onClick={async () => {
        const result = await pick({
          title: "What kind of URL do you want?",
          message:
            "Click one of the options below to select a fast-responding development version, or a scalable production URL.",
          options: [
            {
              title: "Development Version",
              subtitle:
                "Use this when you are still working on your site. Up to date, but slower to load at scale",
              key: "dev",
            },
            {
              title: "Production Version",
              subtitle:
                "Use this when you're getting more traffic on your site. Faster to respond, but uses more caching",
              key: "prod",
            },
          ],
          cancelLabel: "Cancel",
        });
        if (result === "dev") {
          clipboardCopy(bareDevEmbedUrl);
          toast.success("Copied development URL to clipboard");
        } else if (result === "prod") {
          const url = await publish(id, true);
          clipboardCopy(url);
          toast.success("Copied production URL to clipboard");
        }
      }}
    >
      <GlobeAltIcon
        className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
        aria-hidden="true"
      />
      Copy URL
    </button>
  );
};
export default EmbedRawUrl;
