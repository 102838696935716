import React, {
  FC,
  useState,
  useEffect,
  useRef,
  Fragment,
  useMemo,
  ElementRef,
} from "react";
import MonacoEditor, { useMonaco } from "@monaco-editor/react";
import clipboardCopy from "clipboard-copy";
import {
  EmbedData,
  useBase64Uri,
  useEmbed,
  useShare,
  useShareLink,
} from "./EmbedProviderBase";
import LanguageSwitch from "./LanguageSwitch";
import { useHome } from "./HomeBase";
import SidebarPortal from "./SidebarPortal";
import { toast } from "react-toastify";
import { SHA256 } from "crypto-js";
import { useDebounce } from "./useDebounce";
import {
  ClipboardDocumentIcon,
  ClipboardIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CodeBracketSquareIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  FolderPlusIcon,
  HeartIcon,
  LinkIcon,
  LockClosedIcon,
  LockOpenIcon,
  PaperClipIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useAuthenticatedFetch, useAuthenticatedQuery } from "./Authenticator";
import { Link, useNavigate } from "react-router-dom";
import { useRemove } from "./useRemove";
import { format, check } from "prettier";
import prettierBabel from "prettier/parser-babel";
import prettierCss from "prettier/parser-postcss";
import prettierHtml from "prettier/parser-html";
import { useModalPicker } from "./ModalPicker";
import { SCMProject } from "./Projects";
import { useMoveScript } from "./useMoveScript";
import { saveAs } from "file-saver";
import { Uri } from "monaco-editor";
import WewebEmbed from "./WewebEmbed";
import EmbedTag from "./EmbedTag";
import PartyKitEditor from "./PartyKitEditor";
import EmbedRawUrl from "./EmbedRawUrl";
import { XanoClient } from "@xano/js-sdk/lib/client";
// import type { XanoClient as XanoClientType } from "@xano/js-sdk";
let xanoChannel: any | undefined;
const Editor: FC = () => {
  const {
    body,
    data,
    isLoading,
    save,
    saving,
    setType,
    type,
    unsaved,
    name,
    setBody,
    setName,
    setIsShared,
    isShared,
    setLoaded,
    refetch,
    description,
    setDescription,
  } = useEmbed();

  const isPK = !!parseInt(localStorage.getItem("isPK") || "0");
  // console.log("I am a PK", isPK);
  const [isEditing, setIsEditing] = useState(true);
  const rootRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const projectData = data as { project: SCMProject } | undefined;
  const project = projectData?.project;
  const sharedWidth = project?.shared_with || [];
  const sharedWidthLength = sharedWidth.length;
  useEffect(() => {
    if (!sharedWidthLength) return;
    setIsEditing(false);
  }, [sharedWidthLength]);
  //@TODO RHD
  // const monaco = useMonaco()
  // monaco?.getEditor().getPosition()
  useEffect(() => {
    if (!rootRef.current || !rootRef.current.parentElement?.parentElement)
      return;
    const ho = new ResizeObserver((entries) => {
      setHeight(entries[0].contentRect.height);
    });
    ho.observe(rootRef.current.parentElement);
    const wo = new ResizeObserver((entries) => {
      // console.log("Width detected", entries[0]);
      const newWidth = entries[0].contentRect.width; // - entries[0].contentRect.left;
      // console.log("I will set width to ", newWidth);
      setWidth(newWidth);
    });
    wo.observe(rootRef.current.parentElement.parentElement);
    return () => {
      ho.disconnect();
      wo.disconnect();
    };
  }, [data]);
  useEffect(() => {
    const windowWidth = window.innerWidth;
    const leftPos = rootRef.current?.getBoundingClientRect().left;
    if (typeof leftPos !== "undefined") {
      if (width > windowWidth - leftPos - 20) {
        setWidth(windowWidth - leftPos - 20);
      }
    }
  }, [width]);
  const [showCompiled, setShowCompiled] = useState(false);
  useEffect(() => {
    if (showCompiled && isEditing) setIsEditing(false);
  }, [showCompiled, isEditing]);
  const { data: compiledData, refetch: refetchCompiled } =
    useAuthenticatedQuery<{
      compiled: string;
    }>(`/me/compiled/${data?.id}`, undefined, !data?.id);
  const refetchRef = useRef(refetchCompiled);
  refetchRef.current = refetchCompiled;
  useEffect(() => {
    if (showCompiled) refetchRef.current();
  }, [showCompiled]);
  const { setTitle } = useHome();
  useEffect(() => {
    if (name)
      setTitle(
        name +
          (type === "javascript"
            ? ".js"
            : type === "css"
            ? ".css"
            : type === "html"
            ? ".html"
            : "") +
          (isEditing ? "" : " (read-only)")
      );
    else if (isLoading) setTitle("");
    else setTitle("Untitled Document" + (isEditing ? "" : " (read-only)"));
  }, [name, setTitle, unsaved, type, isLoading, isEditing]);
  const refetchReference = useRef(refetch);
  refetchReference.current = refetch;
  const isEditingRef = useRef(isEditing);
  isEditingRef.current = isEditing;
  useEffect(() => {
    (async () => {
      if (!data?.guid) return;
      if (xanoChannel) xanoChannel.destroy();
      const xanoClient = new XanoClient({
        instanceBaseUrl: process.env.REACT_APP_XANO_BASE_URL,
        realtimeConnectionHash: process.env.REACT_APP_XANO_RT_HASH,
      });
      const channel = `statechange/${btoa(data?.guid)}`;
      // console.log("Connecting to xanoChannel", channel);
      xanoChannel = xanoClient.channel(channel);

      xanoChannel.on(
        (action: { action: string; payload: Record<string, any> }) => {
          if (action.action === "event") {
            // toast.success("Got a notice from Xano about this changin my boy");
            // console.log("Got payload", action);
            if (!action.payload?.data) return;
            if (!isEditingRef.current || haveSavedRef.current) {
              // toast.success("I will refetch the data");
              refetchReference.current();
            } else {
              toast.warning("Someone else just made a change to this file!");
            }
          } else {
            // console.log("Got some other message", action);
          }
        }
      );
    })();
  }, [data?.guid]);

  // const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
  // useEffect(() => {
  //   if (intervalRef.current) clearInterval(intervalRef.current);
  //   if (!isEditing) {
  //     intervalRef.current = setInterval(() => {
  //       refetch();
  //     }, 5000);
  //   } else {
  //     intervalRef.current = undefined;
  //   }
  //   return () => {
  //     if (intervalRef.current) clearInterval(intervalRef.current);
  //   };
  // }, [isEditing, refetch]);
  const debouncedSave = useDebounce(SHA256(body + type).toString(), 1000);
  const saveRef = useRef(save);
  saveRef.current = save;
  const haveSavedRef = useRef(false);
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  useEffect(() => {
    if (!isEditing) return;
    if (!isLoadingRef.current) {
      if (!haveSavedRef.current) {
        console.log("I will not save because I have not saved yet");
        haveSavedRef.current = true;
        return;
      }
      console.log("I decided to save", debouncedSave);
      saveRef.current();
    }
  }, [debouncedSave, isEditing]);
  const share = useShare();
  const sharedUrl = useShareLink();
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  const remove = useRemove();
  const moveScript = useMoveScript();
  const checked = useMemo(() => {
    try {
      return (
        check(body.replaceAll("\r\n", "\n"), {
          parser: type === "javascript" ? "babel" : "css",
          plugins: [
            type === "javascript"
              ? prettierBabel
              : type === "css"
              ? prettierCss
              : prettierHtml,
          ],
        }) ||
        check(body.replaceAll("\r\n", "\n") + "\n", {
          parser: type === "javascript" ? "babel" : "css",
          plugins: [
            type === "javascript"
              ? prettierBabel
              : type === "css"
              ? prettierCss
              : prettierHtml,
          ],
        })
      );
    } catch (e) {
      return false;
    }
  }, [body, type]);
  const pick = useModalPicker();
  const monaco = useMonaco();
  useEffect(() => {
    if (monaco) {
      monaco.editor
        .getModel(new Uri())
        ?.setEOL(monaco.editor.EndOfLineSequence.LF);
    }
  }, [monaco]);
  const editorRef = useRef<ElementRef<typeof PartyKitEditor>>(null);
  const getBase64 = useBase64Uri();
  if (!data) return null;
  // if (!width) return null;
  return (
    <div
      className="relative w-full"
      ref={rootRef}
      style={{
        height: height - 25,
        width: width,
        left: 0,
      }}
    >
      <SidebarPortal>
        <div className="text-white flex-col m-2 space-y-4">
          <div className="w-full mx-2">
            <div className=" text-xs text-gray-400">Nickname of document</div>
            <input
              className="bg-black p-2 rounded-md text-white w-full"
              value={name}
              onChange={(e) => {
                //   update({ ...data, name: e.target.value });

                setName(e.target.value);
                save();
              }}
            />
          </div>
          <div className="w-full mx-2">
            <div className=" text-xs text-gray-400">Description </div>
            <input
              className="bg-black p-2 rounded-md text-white w-full"
              value={description}
              onChange={(e) => {
                //   update({ ...data, name: e.target.value });

                setDescription(e.target.value);
                save();
              }}
            />
          </div>
          <div className="w-full mx-2">
            <div className=" text-xs text-gray-400">Type of Embed</div>
            <LanguageSwitch language={type} setLanguage={setType} />
          </div>
          <div className="w-full mx-2">
            {!isEditing && (
              <button
                className="bg-green-500 hover:bg-green-600 text-white p-2 rounded-md w-full flex flex-row justify-center"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <PencilIcon className="h-4 w-4 mr-2 mt-1" />
                Start Editing
              </button>
            )}
            {isEditing && (
              <button
                className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-md w-full flex flex-row justify-center"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <XCircleIcon className="h-4 w-4 mr-2 mt-1" />
                Stop Editing
              </button>
            )}
            {body.includes("@scminclude") && (
              <button
                className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                onClick={() => {
                  setShowCompiled((o) => !o);
                }}
              >
                <CodeBracketSquareIcon
                  className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                  aria-hidden="true"
                />
                {showCompiled ? "Show Editor" : "Show Compiled Preview"}
              </button>
            )}
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                // console.log("Hello");
                const response = await fetch(`/me/embed`);
                const { items: list } = (await response.json()) as {
                  items: EmbedData[];
                };
                // console.log("I have a list", list);
                const filteredList = list.filter(
                  (e) => e.type === type && e.id !== data.id
                );
                if (filteredList.length === 0) {
                  toast.error("You don't have any other files of this type");
                  return;
                }
                const keyString = await pick({
                  options: filteredList.map((e) => ({
                    title: e.name.split("/").pop() || "Untitled file",
                    subtitle: e.name + " (" + e.id + ")",
                    key: e.id.toString(),
                  })),
                  title: "Pick a file to embed",
                  message:
                    "Select one of your files to include in this package",
                });
                if (type === "javascript") {
                  if (isPK) {
                    editorRef.current?.prepend(
                      "//@scminclude " + keyString + " " + name + "\n"
                    );
                  } else {
                    setBody(
                      "//@scminclude " + keyString + " " + name + "\n" + body
                    );
                  }
                } else if (type === "css") {
                  if (isPK) {
                    editorRef.current?.prepend(
                      "/* @scminclude " + keyString + " " + name + " */\n"
                    );
                  } else {
                    setBody(
                      "/* @scminclude " +
                        keyString +
                        " " +
                        name +
                        " */" +
                        "\n" +
                        body
                    );
                  }
                }
                toast.success("Added the include statement");
              }}
            >
              <PaperClipIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Include {body.includes("@include") ? "another" : "a"} file
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                if (isPK) {
                  editorRef.current?.prettify();
                } else {
                  try {
                    const newBody = format(body.replaceAll("\r\n", "\n"), {
                      parser:
                        type === "javascript"
                          ? "babel"
                          : type === "css"
                          ? "css"
                          : type === "html"
                          ? "html"
                          : "",
                      plugins: [prettierBabel, prettierCss, prettierHtml],
                    });
                    setBody(newBody);
                    if (
                      check(newBody, {
                        parser:
                          type === "javascript"
                            ? "babel"
                            : type === "css"
                            ? "css"
                            : type === "html"
                            ? "html"
                            : "",
                        plugins: [prettierBabel, prettierCss, prettierHtml],
                      })
                    ) {
                      toast.success("Prettified code!");
                    } else {
                      toast.error("Prettified code, but it's still invalid");
                    }
                  } catch (e) {
                    console.error(e);
                    toast.error("Failed to prettify: " + (e as Error).message);
                  }
                }
              }}
            >
              <HeartIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Prettify code
            </button>
            <EmbedTag />
            <EmbedRawUrl />
            <WewebEmbed />
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                navigator.clipboard.writeText(getBase64());
                toast.success("Copied base64 data URI to clipboard");
              }}
            >
              <ClipboardDocumentIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Copy Base64 data URI
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                clipboardCopy(body);
                toast.success("Copied text to clipboard");
              }}
            >
              <ClipboardIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Copy text to clipboard
            </button>

            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                const response = await fetch("/me/embed", {
                  method: "POST",
                  body: JSON.stringify({
                    name: name + " (copy)",
                    type,
                    body,
                  }),
                });
                if (response.ok) {
                  const data: EmbedData = await response.json();
                  toast.success("Copied " + name);
                  // console.log("Got back answer of ", data);
                  moveScript(data.id, () => {
                    setLoaded(false);
                    navigate(`/${data.id}`);
                    window.location.reload();
                  });
                } else {
                  toast.error("Failed to clone");
                }
              }}
            >
              <DocumentDuplicateIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Duplicate
            </button>
            {!isShared && (
              <button
                className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                onClick={async () => {
                  await share();
                }}
              >
                <LockOpenIcon
                  className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                  aria-hidden="true"
                />
                Enable Sharing
              </button>
            )}

            {isShared && (
              <Fragment>
                <button
                  className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                  onClick={() => {
                    if (sharedUrl) {
                      clipboardCopy(sharedUrl);
                      toast.success("Copied share link to clipboard");
                    } else {
                      toast.error("Could not share the document");
                    }
                  }}
                >
                  <LinkIcon
                    className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                    aria-hidden="true"
                  />
                  Copy Share Link
                </button>
                <button
                  className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                  onClick={() => {
                    setIsShared(false);
                  }}
                >
                  <LockClosedIcon
                    className="text-red-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                    aria-hidden="true"
                  />
                  Disable Sharing
                </button>
              </Fragment>
            )}
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                remove(data.id, () => {
                  navigate("/");
                });
              }}
            >
              <TrashIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Delete
            </button>
            <Link
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              // onClick={() => {
              //   if (project?.id) navigate("/project/" + project?.id);
              //   else navigate("/");
              // }}
              to={project?.id ? "/project/" + project?.id : "/"}
            >
              <FolderIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              {project?.name || "Unassigned"}
            </Link>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                moveScript(data.id, refetch);
              }}
            >
              <FolderPlusIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Move to Another Project
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={async () => {
                saveRef.current();
              }}
            >
              <CloudArrowUpIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Save to Cloud
            </button>
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                saveAs(
                  new Blob([body], { type: "plain/text" }),
                  name +
                    (type === "javascript"
                      ? ".js"
                      : type === "css"
                      ? ".css"
                      : type === "html"
                      ? ".html"
                      : "")
                );
              }}
            >
              <CloudArrowDownIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Download file
            </button>
          </div>
        </div>
      </SidebarPortal>
      <div className="flex flex-row w-full justify-end space-x-4">
        {!showCompiled && (
          <Fragment>
            <div className="text-gray-200 px-2">
              <button
                onClick={() => {
                  editorRef.current?.prettify();
                }}
              >
                {checked ? (
                  <span className="px-2 text-xs bg-green-800 rounded-full p-1">
                    Prettified!
                  </span>
                ) : (
                  <span className="px-2 text-xs bg-red-800 rounded-full p-1">
                    Not Prettified
                  </span>
                )}
              </button>
            </div>
            <div className=" text-gray-200">
              <button onClick={save} disabled={!unsaved}>
                {unsaved ? (
                  <span className="text-xs px-2 bg-red-800 rounded-full p-1">
                    Not yet saved
                  </span>
                ) : saving ? (
                  <span className="text-xs px-2 bg-yellow-800 rounded-full p-1">
                    Saving...
                  </span>
                ) : (
                  <span className="text-xs px-2 bg-green-800 rounded-full p-1">
                    Saved on server
                  </span>
                )}
              </button>
            </div>
          </Fragment>
        )}
        {showCompiled && (
          <div className="text-gray-200">
            <button onClick={() => setShowCompiled(false)}>
              <span className="text-xs px-2 bg-green-800 rounded-full p-1">
                Showing Preview - Go Back to Editor
              </span>
            </button>
          </div>
        )}
      </div>
      {!showCompiled && !isPK ? (
        <MonacoEditor
          className="my-2"
          language={type || "javascript"}
          theme="vs-dark"
          value={body}
          options={{ wordWrap: "on", readOnly: !isEditing }}
          onChange={(body) => {
            if (!isLoading) {
              setBody(typeof body !== "undefined" ? body : "");
            }
          }}
        />
      ) : (
        <PartyKitEditor
          docId={data.id}
          isEditing={isEditing}
          type={data.type}
          ref={editorRef}
        />
      )}
      {showCompiled && (
        <MonacoEditor
          className="my-2"
          language={type || "javascript"}
          theme="vs-dark"
          value={compiledData?.compiled || ""}
          options={{ wordWrap: "on", readOnly: true, renderFinalNewline: "on" }}
        />
      )}
    </div>
  );
};

export default Editor;
