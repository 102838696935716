import { FC } from "react";
import Home from "./Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authenticator from "./Authenticator";
import Login from "./Login";
import Signup from "./Signup";
import AlertProvider from "./Alert";
import SharedHome from "./SharedHome";
import ModalPicker from "./ModalPicker";
import LoginForm from "./LoginForm";

const search = window.location.search;
const q = new URLSearchParams(search);
const pk = q.get("pk");
if (pk !== null) {
  localStorage.setItem("isPK", pk as string);
  window.location.replace(window.location.pathname);
}

const App: FC = () => {
  return (
    <BrowserRouter>
      <AlertProvider>
        <ModalPicker>
          <Authenticator
            fallback={
              <Routes>
                <Route path="/signup" element={<Signup />} />
                <Route path="/login-form" element={<LoginForm />} />
                <Route path="*" element={<Login />} />
                <Route path="/shared/:guid" element={<SharedHome />} />
                <Route path="/embed/:guid" element={<SharedHome />} />
              </Routes>
            }
          >
            <Home />
          </Authenticator>
        </ModalPicker>
      </AlertProvider>

      <ToastContainer hideProgressBar position="bottom-right" limit={2} />
    </BrowserRouter>
  );
};

export default App;
