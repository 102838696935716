import { FC } from "react";
import { CodeBracketIcon, TrashIcon } from "@heroicons/react/20/solid";
import { EmbedData } from "./EmbedProviderBase";
import { Link } from "react-router-dom";
import { useRemove } from "./useRemove";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import { useMoveScript } from "./useMoveScript";
const EmbedList: FC<{ items: EmbedData[]; onRemove?: () => void }> = ({
  items,
  onRemove,
}) => {
  const remove = useRemove();
  const moveScript = useMoveScript();
  if (!items.length)
    return (
      <div className=" w-full bg-white rounded-md p-4">
        <div className="w-full p-4  text-xl flex justify-center">
          <div>No embeds found</div>
        </div>
      </div>
    );
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md w-full p-5">
      <ul className="divide-y divide-gray-200">
        {items
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((embed) => (
            <li key={embed.id}>
              <Link to={`/${embed.id}`} className="block hover:bg-gray-50">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="truncate text-sm font-medium text-indigo-600">
                        {embed.name}
                      </p>
                      <p className="truncate text-xs text-gray-600">
                        {embed.description}
                      </p>
                    </div>
                    <div className="ml-2 flex flex-shrink-0">
                      {embed.is_shared && (
                        <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          Shared
                        </p>
                      )}
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        #{embed.id}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <CodeBracketIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {embed.type}
                      </p>
                    </div>
                    <div className="flex-row flex space-x-4">
                      <button
                        className="mt-2 flex items-center text-sm text-blue-500 hover:text-blue-700 sm:mt-0"
                        onClick={(e) => {
                          moveScript(embed.id, onRemove);
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        }}
                      >
                        <FolderPlusIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0"
                          aria-hidden="true"
                        />
                        <p>Move to Project</p>
                      </button>
                      <button
                        className="mt-2 flex items-center text-sm text-red-500 hover:text-red-700 sm:mt-0"
                        onClick={(e) => {
                          console.log("removing");
                          remove(embed.id, onRemove);
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        }}
                      >
                        <TrashIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0"
                          aria-hidden="true"
                        />
                        <p>Delete</p>
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
export default EmbedList;
