import { Routes, Route, Navigate } from "react-router-dom";
import EditEmbed from "./EditEmbed";
import SharedEditEmbed from "./SharedEditEmbed";
import Projects from "./Projects";
import Project from "./Project";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Projects />} />
      <Route path="/project/:id" element={<Project />} />
      <Route path="/shared/:guid" element={<SharedEditEmbed />} />
      <Route path="/embed/:guid" element={<SharedEditEmbed />} />
      <Route path="/:id" element={<EditEmbed />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default MainRoutes;
