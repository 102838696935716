import Logo from "./Logo";

export default function CTA() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl ">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-8 shadow-2xl sm:rounded-3xl sm:px-16  md:flex md:gap-x-20 md:px-24 md:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="blue" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-lg md:max-w-md text-center md:mx-0 md:flex-auto md:py-5 md:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              <Logo className="inline-block h-8 w-8 pt-1 mr-2" />
              Join State Change
            </h2>
            {/* <h2 className="text-2xl font-semibold tracking-tight text-white sm:text-3xl mt-5">
              What Is Your Hardest 5%?
            
            </h2> */}
            <p className="mt-3 text-sm leading-5 md:leading-6 text-gray-300">
              Make progress on your toughest challenges with members-only access
              to <b>daily office hours</b>, a vault of{" "}
              <b>200+ hours of video</b> on the hardest problems in no-code, and
              amazing member-only <b>tools - Like Script Tag! - </b> to help you
              build <i>faster</i>!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 md:justify-start">
              <a
                href="https://statechange.ai/join"
                target="_blank"
                rel="noreferrer"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Apply for Statechange Membership Today
              </a>
              <a
                href="https://statechange.ai"
                target="_blank"
                rel="noreferrer"
                className="text-sm font-semibold leading-6 text-white"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="hidden md:block relative h-40 ">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src="https://statechange.ai/lib_UxhkzrGKMwIAQvtr/zvb417zly82mndho.png?w=800&h=500&fit=crop&dpr=2"
              alt="App screenshot"
              width={1824}
              height={200}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
