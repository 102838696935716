import { PlusIcon } from "@heroicons/react/24/outline";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedFetch } from "./Authenticator";
import { EmbedData } from "./EmbedProviderBase";
import { MPOption, useModalPicker } from "./ModalPicker";

const templates: (MPOption & { body: string; type: string })[] = [
  {
    key: "js-webflow",
    title: "Webflow (push) base",
    body: `var Webflow = window.Webflow || [];
  Webflow.push(()=>{
    //let's gooooo
  })`,
    subtitle:
      "Webflow-centric script that runs when page is fully loaded using webflow.push",
    type: "javascript",
  },
  {
    key: "js-base",
    title: "Bare JavaScript",
    body: "//let's gooooo",
    subtitle: "Just a simple JavaScript starter template",
    type: "javascript",
  },
  {
    key: "css-base",
    title: "Bare CSS",
    subtitle: "Just a simple CSS starter template",
    body: `body {
      background-color: green;
    }`,
    type: "css",
  },
  {
    key: "html-base",
    title: "Bare HTML",
    subtitle: "Just a simple HTML starter template",
    body: `<html><head><title></title></head><body></body></html>`,
    type: "html",
  },
];

const AddEmbed: FC<{ projectId: number; onAdd?: () => void }> = ({
  onAdd = () => {},
  projectId,
}) => {
  const pick = useModalPicker();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const add = useCallback(async () => {
    const result = await pick({
      title: "Create a new Script",
      message: "Select a template for your new script",
      options: templates,
    });
    const template = templates.find((t) => t.key === result);
    if (template) {
      const response = await fetch("/me/embed", {
        method: "POST",
        body: JSON.stringify({
          name:
            "Embed created " + new Date().toLocaleString().replace(/\//g, "-"),
          type: template.type,
          body: template.body,
          project_id: projectId,
        }),
      });
      const data: EmbedData = await response.json();
      if (onAdd) onAdd();
      navigate("/" + data.id);
    }
  }, [fetch, onAdd, navigate, pick, projectId]);

  return (
    <button
      className=" w-full bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
      onClick={add}
    >
      <PlusIcon className="h-5 w-5 inline-block mr-2" />
      {projectId
        ? "Create a new Script File"
        : "Create a new Script File Without a Project"}
    </button>
  );
};
export default AddEmbed;
