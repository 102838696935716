import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "./Logo";
import { Link, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import SharedEditEmbed from "./SharedEditEmbed";
import { Provider } from "./HomeBase";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function SharedHome() {
  const { pathname } = useLocation();
  const { guid } = useParams();
  const isEmbed = pathname?.startsWith("/embed/");
  const [title, setTitle] = useState<ReactNode>("Untitled");
  const [pageKey, setPageKey] = useState("");
  const navigation: {
    name: string;
    href: string;
    icon: FunctionComponent<any>;
    current: boolean;
  }[] = [
    // {
    //   name: "My Embeds",
    //   href: "/",
    //   icon: FolderIcon,
    //   current: pageKey === "embedlist",
    // },
    // { name: "Dashboard", href: "#", icon: HomeIcon, current: false },
    // { name: "Team", href: "#", icon: UsersIcon, current: false },
    // { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
    // { name: "Documents", href: "#", icon: InboxIcon, current: false },
    // { name: "Reports", href: "#", icon: ChartBarIcon, current: false },
  ];
  const value = useMemo(
    () => ({
      title,
      setTitle,
      pageKey,
      setPageKey,
    }),
    [title, pageKey]
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <Provider value={value}>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <Helmet>
        <title>{title} | ScriptTag by State Change</title>
      </Helmet>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4 text-white">
                    <Logo className="h-8 w-8" />
                  </div>
                  <div className="sidebar-portal" />
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    {/* <div id="portal" /> */}
                    <nav className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center rounded-md px-2 py-2 text-base font-medium"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-4 h-6 w-6 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex h-16 flex-shrink-0 items-center bg-gray-900 px-4 text-white font-medium">
              <Logo className="h-8 w-8 mr-2" /> Scripttag.me
            </div>
            <div className="sidebar-portal" />
            <div className="flex flex-1 flex-col overflow-y-auto">
              <nav className="flex-1 space-y-1 px-2 py-4">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 h-6 w-6 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div
          className={
            (isEmbed ? "border-2 border-gray-900" : "") +
            " flex flex-col lg:pl-64 h-screen"
          }
        >
          <div
            className={
              (isEmbed ? "bg-gray-300" : "bg-white") +
              " absolute sticky top-0 z-10 flex h-16 flex-shrink-0 shadow"
            }
          >
            {!isEmbed && (
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            )}
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1 ">
                {typeof title === "string" ? (
                  <h1
                    className={
                      (isEmbed ? "text-md hover:text-blue-900" : "text-2xl") +
                      " whitespace-nowrap text-ellipsis py-5  font-semibold text-gray-900 overflow-hidden"
                    }
                  >
                    {isEmbed ? (
                      <a
                        className="hover:text-blue-900 group cursor-pointer"
                        href={"https://scripttag.statechange.ai/shared/" + guid}
                        target="_blank"
                        rel="noreferrer"
                        title="Open in New Window"
                      >
                        {title}{" "}
                        <ArrowUpOnSquareIcon className="inline-block h-4 w-4 mr-1" />
                      </a>
                    ) : (
                      title
                    )}
                  </h1>
                ) : (
                  title
                )}
              </div>
              <div className="ml-4 flex items-center lg:ml-6">
                {/* <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                {!isEmbed && (
                  <Link
                    to="/"
                    className="bg-blue-800 p-1 px-4 rounded-full text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Sign In
                  </Link>
                )}
                {/* <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-blue-800 w-8 h-8 text-white  text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      {userData?.avatar_url ? (
                        <img
                          src={userData.avatar_url}
                          alt={userData.name}
                          className="rounded-full w-8 h-8"
                        />
                      ) : (
                        <UserIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={() => {
                                // if (item.clickHandler === "signout") {
                                //   logout();
                                // }
                              }}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu> */}
              </div>
            </div>
          </div>

          <main className="flex-1 flex">
            {/* <div className="py-6 flex-1 flex flex-col"> */}
            {/* <div className=" max-w-7xl px-4 sm:px-6 lg:px-8"></div> */}
            {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex-1 pt-5"> */}
            <div className={"mx-auto  flex-1 " + isEmbed ? "" : "  pt-5"}>
              <SharedEditEmbed />
            </div>
            {/* </div> */}
          </main>
        </div>
      </div>
    </Provider>
  );
}
