import { useParams } from "react-router-dom";
import EmbedProvider from "./SharedEmbedProvider";
import SharedEditor from "./SharedEditor";

const SharedEditEmbed = () => {
  const { guid } = useParams();
  console.log("Working iwth id of ", guid);
  if (!guid) return null;
  return (
    <EmbedProvider guid={guid}>
      <SharedEditor />
    </EmbedProvider>
  );
};

export default SharedEditEmbed;
