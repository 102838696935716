import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactNode,
} from "react";
import { SHA256 } from "crypto-js";
import { useData } from "./useData";
import { EmbedData, Provider } from "./EmbedProviderBase";
import { useAuthenticatedFetch } from "./Authenticator";
import { toast } from "react-toastify";

const SharedEmbedProvider: FC<{ id: number; children: ReactNode }> = ({
  children,
  id,
}) => {
  const [body, setBody] = useState("");
  const bodyHash = useMemo(() => {
    return SHA256(body).toString();
  }, [body]);
  const [savedBody, setSavedBody] = useState(SHA256("").toString());
  const [loaded, setLoaded] = useState(false);
  const fetch = useAuthenticatedFetch();
  const { data, isLoading, error, update, refetch } = useData(
    useCallback(async () => {
      if (!id) return undefined;
      const response = await fetch(`/me/embed/${id}`);
      const data = (await response.json()) as EmbedData;
      return data;
    }, [id, fetch]),
    [id, fetch]
  );
  const bodyHashRef = useRef(bodyHash);
  bodyHashRef.current = bodyHash;
  const savedBodyRef = useRef(savedBody);
  savedBodyRef.current = savedBody;
  const [type, setType] = useState("javascript");
  useEffect(() => {
    if (data) {
      if (!loaded) {
        setBody(data.body);
        setLoaded(true);
        setSavedBody(SHA256(data.body).toString());
        setType(data.type);
        setName(data.name);
        setDescription(data.description);
        // console.log("A I set name to ", data.name);
        setIsShared(data.is_shared);
      } else if (
        SHA256(data.body).toString() !== bodyHashRef.current &&
        bodyHashRef.current === savedBodyRef.current
      ) {
        setBody(data.body);
        setSavedBody(SHA256(data.body).toString());
        setType(data.type);
        setName(data.name);
        // console.log("B I set name to ", data.name);
        setIsShared(data.is_shared);
        setDescription(data.description);
      } else {
        // setType(data.type);
        // setName(data.name);
        // console.log("C I set name to ", data.name);
        // setIsShared(data.is_shared);
      }
    }
  }, [data, loaded, type]);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isShared, setIsShared] = useState(false);
  const [saveAgain, setSaveAgain] = useState(false);
  const save = useCallback(async () => {
    if (!data?.id) return;
    if (saving) {
      setSaveAgain(true);
      return;
    }
    setSaving(true);
    setSavedBody(SHA256(body).toString());
    const response = await fetch(`/me/embed/${data?.id}`, {
      method: "POST",
      body: JSON.stringify({
        body,
        type,
        name,
        is_shared: isShared,
        description,
      }),
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      setSaving(false);
    } else {
      setSaving(false);
      toast.error("Failed to save embed");
    }
  }, [saving, data, body, type, fetch, name, isShared, description]);
  useEffect(() => {
    if (saveAgain && !saving) {
      setSaveAgain(false);
      save();
    }
  }, [saveAgain, saving, save]);
  const value = useMemo(() => {
    return {
      body,
      setBody,
      type,
      setType,
      isLoading,
      error,
      update,
      refetch,
      save,
      saving,
      data,
      unsaved: bodyHash !== savedBody,
      setName,
      name,
      isShared,
      setIsShared,
      loaded,
      setLoaded,
      description,
      setDescription,
    };
  }, [
    body,
    error,
    isLoading,
    refetch,
    save,
    saving,
    type,
    update,
    data,
    bodyHash,
    savedBody,
    setName,
    name,
    isShared,
    setIsShared,
    loaded,
    setLoaded,
    description,
    setDescription,
  ]);
  return <Provider value={value}>{children}</Provider>;
};
export default SharedEmbedProvider;
